main {
	&.main {
		&.manifesto {

			.bg {
				background: #f8f8f8;
				padding: 15px;
				border: 1px solid #dee2e6 !important;
				border-radius: 6px;
			}

			.titulo {
				color: $secondaryColor;

				p {
					color: inherit;
					max-width: 200px;
				}

				+ p {
					font-size: 1em;
				}
			}

			.timeline-block {
				cursor: pointer;

				.timeline-content {
					h6 {
						white-space: nowrap;
						max-width: 100%;
						text-overflow: ellipsis;
						overflow: hidden;
					}
				}
			}

			.list-group {
				.anexos {

					a {
						text-transform: lowercase;
						color: $textColor;

						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
		}
	}
}