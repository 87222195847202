.select2-container {
	.select2-selection--single {
		height: 38px;

		.select2-selection__rendered {
			line-height: 38px;
		}

		.select2-selection__arrow {
			height: 36px;
		}
	}
}