body {
	&.menu-fixo {

		header {
			&.header-sticky {
				position: fixed;
				width: 100%;

				.header-body {

					.header-column {

						&:last-child {
							.wrap-menu {
								.row {
									min-height: 70px;
								}
							}
						}

						.hidden-if-sticked {
							display: none;
						}

						.header-logo {
							width: 200px;
							max-width: 100%;
						}
					}
				}
			}
		}

		main {
			padding-top: 65px;
		}
	}

	&.border-shadow {
		header {
			&.header-sticky {
				border-bottom: 1px solid $primaryBorderColor;
				box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
			}
		}
	}
}

header {

	&.header-sticky {
		background-color: #222342;
		z-index: 10;

		ul {
			&.area-parceiro {
				li {
					line-height: 24px;

					&:not(:last-child) {
						a {
							&:hover {
								background-color: $secondaryColor;
								color: $extraColor2;
							}
						}
					}

					a {
						font-size: 12.6px;
						border-radius: 30px;
						color: $textColor;
						margin: 0 2.5px;
						display: inline-block;
						padding: 6px 10px;

						> i {
							margin-right: 5px;
						}
					}

					&:first-child {
						a {
							margin-left: 0;
							background-color: $secondaryColor;
							color: $extraColor2;

							&:hover {
								background-color: $hoverSecondaryColor;
							}
						}
					}

					&:last-child {
						a {
							margin-right: 0;
							cursor: unset;
						}
					}
				}
			}

			&.area-menu {
				li {
					margin: 0 .5px;
					line-height: 24px;

					&:first-child {
						margin-left: 0;
					}

					&:last-child {
						margin-right: 0;
					}

					a {
						font-family: $font;
						font-weight: 700;
						font-size: 12px;
						padding: 12px;
						text-transform: uppercase;
						border-radius: 4px;
						color: $extraColor2;

						&:hover {
							//background-color: $primaryColor;
							color: $extraColor4;
						}
					}

					#btnLogin {
						background: #f8f8f8;
						color: #222342;
						padding: 10px 20px;
						margin-left: 10px;
						border: 1px solid #f8f8f8;

						&:hover {
							background: transparent!important;
							color: #f8f8f8;
							border: 1px solid #f8f8f8;
						}
					}
				}
			}

			&.midia-social {
				li {
					border-radius: 50%;
					box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
					margin: 0 4px;
					transition: all .3s linear;

					&:first-child {
						margin-right: 0;
					}

					&:last-child {
						margin-left: 0;
					}

					a {
						display: flex;
						justify-content: center;
						align-items: center;
						width: 30px;
						height: 30px;
						color: $extraColor3;
					}

					&.midia-facebook {
						&:hover {
							background-color: $facebookColor;

							a {
								color: $extraColor2;
							}
						}
					}

					&.midia-instagram {
						&:hover {
							background-color: $instagramColor;

							a {
								color: $extraColor2;
							}
						}
					}

					&.midia-linkedin {
						&:hover {
							background-color: $linkedinColor;

							a {
								color: $extraColor2;
							}
						}
					}
				}
			}
		}

		.header-body {
			.header-column {
				min-height: 70px;

				.header-logo {
					width: 110px;
				}
			}

			.list-menu-mobile {
				max-height: 0;
				overflow: hidden;
				transition: max-height .3s ease-in-out;

				&.active {
					max-height: 85px;
				}

				ul {
					&.area-menu {
						li {
							border-top: 1px solid $primaryBorderColor;
						}
					}
				}
			}
		}

		.bevicred-toggle-menu {
			background-color: $primaryColor;
			border-color: $primaryColor;
			outline: 0;

			&:focus, &:active {
				box-shadow: none;
				background-color: $primaryColor !important;
				border-color: $primaryColor !important;
			}
		}
	}

}

@media (min-width: 991.99px) {

	header {
		&.header-sticky {
			.header-body {
				.list-menu-mobile {
					max-height: 0 !important;
				}
			}
		}
	}

}

@media (min-width: 767.99px) {
	body {
		&.menu-fixo {
			.header-body {
				.header-column {
					.header-logo {
						transition: width .3s ease, height .3s ease;
					}
				}
			}
		}
	}
}

@media (max-width: 767.98px) {

	header {
		&.header-sticky {
			.header-body {
				.header-column {
					.header-logo {
						width: 100%;
					}
				}
			}

			ul {
				&.area-parceiro {
					li {
						a {
							font-size: 9.5px;
						}
					}
				}
			}
		}
	}

}