.fileUploadArea {

	.inputfile {
		width: 0.1px;
		height: 0.1px;
		opacity: 0;
		overflow: hidden;
		position: absolute;
		z-index: -1;

		&+ label {
			max-width: 80%;
			font-size: 1.25rem;
			font-weight: normal;
			text-overflow: ellipsis;
			white-space: nowrap;
			cursor: pointer;
			display: inline-block;
			overflow: hidden;
			padding: 0.625rem 1.25rem;

			svg {
				width: 1em;
				height: 1em;
				vertical-align: middle;
				fill: currentColor;
				margin-top: -0.25em;
				margin-right: 0.25em;
			}
		}
	}

	.box {
		display: flex;
		justify-content: center;
		border: 1px solid $secondaryBorderColor;
		background-color: $primaryBorderColor;
		border-radius: 3px;

		padding: 20px;

		&.has-advanced-upload {
			outline: 2px dashed $secondaryBorderColor;
			outline-offset: -10px;

			.box__dragndrop {
				display: inline;
			}
		}

		&.is-dragover {
			background-color: $extraColor2;
		}

		&.is-uploading {

			.box__input {
				visibility: hidden;
			}

			.box__uploading {
				display: block;
			}
		}
	}

	.box__dragndrop,
	.box__uploading,
	.box__success,
	.box__error {
		display: none;
	}

}