.list-group {
	display: flex;
	flex-direction: column;
	margin-bottom: 0;
	padding-left: 0;

	h5, .h5 {
		font-size: 18px;
	}

	h4, .h4 {
		font-size: 18px;
		font-weight: 600;
	}
}

.list-group-flush {

	&:first-child {
		.list-group-item {
			&:first-child {
				border-top: 0;
			}
		}
	}

	.list-group-item {
		border-right: 0;
		border-left: 0;
		border-radius: 0;

		&:first-child {
			border-top-left-radius: .375rem;
			border-top-right-radius: .375rem;
		}

		h5 {
			font-size: 18px!important;
		}
	}

}

.list-group-item {
	position: relative;
	display: block;
	margin-bottom: -1px;
	padding: 1rem 1rem;
	border: 1px solid #e9ecef;
	background-color: #fff;
}

.list-group-item-action {
	width: 100%;
	text-align: inherit;
	color: #525f7f;
}

.avatar {
	font-size: 1rem;
	display: inline-flex;
	width: 48px;
	height: 48px;
	color: #fff;
	border-radius: .375rem;
	background-color: #adb5bd;
	align-items: center;
	justify-content: center;
}

.avatar-xs {
	font-size: .75rem;
	width: 24px;
	height: 24px;
}