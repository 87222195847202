
#loading-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

#loading-text {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    color: #999;
    width: 120px;
    height: 30px;
    margin: 25px 0 0 -58px;
    text-align: center;
    font-family: 'Nunito', sans-serif;
    font-size: 12px !important;
}

#loading-content {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 200px;
    height: 200px;
    margin: -100px 0 0 -100px;
    border: 3px solid #F00;
}

#loading-content:after {
    content: "";
    position: absolute;
    border: 3px solid #0F0;
    left: 15px;
    right: 15px;
    top: 15px;
    bottom: 15px;
}

#loading-content:before {
    content: "";
    position: absolute;
    border: 3px solid #00F;
    left: 5px;
    right: 5px;
    top: 5px;
    bottom: 5px;
}

#loading-content {
    border: 3px solid transparent;
    border-top-color: #184780;
    border-bottom-color: #184780;
    border-radius: 50%;
    -webkit-animation: loader 2s linear infinite;
    -moz-animation: loader 2s linear infinite;
    -o-animation: loader 2s linear infinite;
    animation: loader 2s linear infinite;
}

#loading-content:before {
    border: 3px solid transparent;
    border-top-color: #638dbf;
    border-bottom-color: #638dbf;
    border-radius: 50%;
    -webkit-animation: loader 3s linear infinite;
    -moz-animation: loader 2s linear infinite;
    -o-animation: loader 2s linear infinite;
    animation: loader 3s linear infinite;
}

#loading-content:after {
    border: 3px solid transparent;
    border-top-color: #a5c2e4;
    border-bottom-color: #a5c2e4;
    border-radius: 50%;
    -webkit-animation: loader 1.5s linear infinite;
    animation: loader 1.5s linear infinite;
    -moz-animation: loader 2s linear infinite;
    -o-animation: loader 2s linear infinite;
}

@-webkit-keyframes loaders {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes loader {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

#loading-wrapper img{
    width: 80px;
    margin-top: -30px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -45px;
}