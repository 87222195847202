.stepper-horizontal {
	display:table;
	width:100%;
	margin:0 auto;

	.step {
		display:table-cell;
		position:relative;
		padding:24px;

		&:first-child {
			&:active {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}

			.step-bar-left {
				display:none;
			}
		}

		&:last-child {
			&:active {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}

			.step-bar-right {
				display:none;
			}
		}

		.step-circle {
			width: 60px;
			height: 60px;
			margin: 0 auto;
			background-color: #f8f8f8;
			border-radius: 8px;
			text-align: center;
			font-size: 16px;
			font-weight: 600;
			color: #666;
			border: 2px solid #cccccc;
			font-family: "Nunito",sans-serif;
			display: flex;
			justify-content: center;
			align-items: center;

			i {
				display: none;
			}

			span {
				display: block;
				margin-top: -1px;
			}
		}

		&.active {
			&.done {
				.step-bar-left, .step-bar-right {
					border-color: $extraColor4;
				}

				.fa-check {
					display: block;
				}
			}

			&.editable {
				.step-bar-left {
					border-color: $extraColor4;
				}

				.fa-edit {
					display: block;
				}
			}
		}

		&.done, &.editable {
			.step-circle {
				* {
					&:not(i) {
						display:none;
					}
				}
			}
		}

		.step-title {
			margin-bottom: 20px;
			font-size:20px;
		}

		.step-title, .step-optional {
			text-align: center;
			font-weight: bold;
			font-family: $font;
			color: $extraColor5;
		}

		.step-optional {
			font-size:12px;
		}

		.step-bar-left, .step-bar-right {
			position:absolute;
			top:calc(45% + 30px);
			height:1px;
			border-top: 2px solid $secondaryBorderColor;
		}

		.step-bar-right {
			right:0;
			left:50%;
			margin-left:30px;
		}

		.step-bar-left {
			left:0;
			right:50%;
			margin-right:30px;
		}

	}

	&.green {
		.step {
			&.active {
				.step-circle {
					background-color:#00AE4D;
				}
			}
		}
	}

	&.orange {
		.step {
			&.active {
				.step-circle {
					background-color:#F96302;
				}
			}
		}
	}

	&.bevicred {
		.step {
			.step-circle {

			}

			&.active {
				.step-circle {
					background-color: $extraColor4;
					color: $extraColor2;
					border: 2px solid $extraColor4;
				}
			}
		}
	}
}

@media (max-width: 1199.98px) {
	.stepper-horizontal {
		.step {
			&:not(.editable) {
				display: none;
			}
		}
	}
}