@media screen and (min-width: 992.99px) {
	.timeline {
		&:before {
			left: 50%;
			margin-left: -2px;
		}
	}

	.timeline-step {
		left: 50%;
	}

	//.timeline-one-side {
	//	.timeline-content {
	//		max-width: 30rem;
	//	}
	//}

	.timeline-content {
		width: 38%;
	}
}

.card {

	border: 0;

	.card-header {
		margin-bottom: 0;
		padding: 1.25rem 1.5rem;
		border-bottom: none!important;
		background-color: #fff;

		h1, h2, h3, h4, h5, h6 {
			font-size: 1.5em;
		}

		h5 {
			font-size: 18px!important;
			top: 3px;
			position: relative;
		}

		h4 {
			font-size: 18px!important;
		}
	}
}

.timeline {
	position: relative;

	&:before {
		position: absolute;
		top: 0;
		left: 1rem;
		height: 100%;
		content: '';
		border-right: 2px solid #e9ecef;
	}

	.timeline-block {
		position: relative;
		margin: 0!important;

		&:first-child {
			margin-top: 0;
		}

		&:after {
			display: table;
			clear: both;
			content: '';
		}
	}
}

.timeline-one-side {

	.timeline-step {
		left: 1rem;
	}

	.timeline-content {
		width: auto;
	}

	&:before {
		left: 1rem;
	}
}

.timeline-step {
	font-size: 1rem;
	font-weight: 600;
	position: absolute;
	z-index: 1;
	left: 0;
	display: inline-flex;
	width: 33px;
	height: 33px;
	transform: translateX(-50%);
	text-align: center;
	border-radius: 50%;
	align-items: center;
	justify-content: center;
}

.timeline-content {
	position: relative;
	top: -6px;
	margin-left: 60px;
	padding-top: .5rem;

	&:after {
		display: table;
		clear: both;
		content: '';
	}

	h6 {
		color: #25cbdb;
		font-size: 18px!important;
	}

	span {
		color: #666;
		font-size: 18px!important;
	}
}

.badge-success {
	color: #1aae6f;
	background-color: #b0eed3;
}

[data-timeline-axis-style=dashed] {
	&:before {
		border-right-style: dashed !important;
	}
}

