.recapitulacao-dados {
	p {
		&.tipo-manifestacao {

			color: $extraColor4 !important;
			font-weight: 700 !important;
			font-size: 20px;
		}
	}

	.descricaoManifesto, .anexos, .envolvidos, .localFatos{
		margin-top: 10px;

		.titulo {
			font-weight: 700;
			text-decoration: underline;
			color: $extraColor6;
			font-size: 18px;
		}
	}

	.anexos, .envolvidos {
		ul {
			padding-inline-start: 40px;
			list-style-type: decimal-leading-zero;

			li {
				font-size: 18px;
				color: $extraColor6 !important;
			}
		}
	}

	.localFatos {
		.descricaoFatos {
			p {
				color: $extraColor6 !important;
			}
		}
	}
}