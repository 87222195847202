.floatBtnBottom {
	position: fixed;
	bottom: 0;
	right: 10px;
	background: #404040;
	border-radius: 7px 7px 0 0;
	color: #FFF;

	transition: opacity 0.3s;

	display: block;
	height: 9px;
	opacity: 0;
	padding: 13px 10px 35px;
	text-align: center;
	text-decoration: none;
	min-width: 49px;
	border: none;
	z-index: 100;

	&.visible {
		opacity: .75;
	}
}