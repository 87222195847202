.tooltip {
	.arrow {
		&:before {
			border-top-color: $primaryColor;
		}
	}

	.tooltip-inner {
		font-family: $font;
		font-weight: 400;
		font-style: italic;
		background-color: $primaryColor;
	}
}


main {
	&.main {
		&.ajuda {

			h2 {
				color: $extraColor5;
				font-weight: bold;
			}

			.listaAjuda {
				background: #f8f8f8;
				padding: 15px;
				border: 1px solid #dee2e6 !important;
				border-radius: 6px;

				p {
					color: #666;
					font-family: "Nunito",sans-serif;
					font-size: 18px;

					&.title {
						color: $extraColor4;
						font-weight: bold;
					}
				}

				li {
					margin-bottom: 10px;
				}

				.titulo {
					color: $primaryColor;
					font-weight: 700;
					font-size: 1.2em;
				}

				.desc {
					font-weight: 100;
					font-size: 1.2em;
				}
			}

		}

		.page-header {
			min-height: 50px;
			height: 130px;
			margin-bottom: 150px;

			.title-page {
				h1 {
					display: inline-block;
					text-align: right;
					font-weight: 900;
					font-size: 2.2em;
					text-transform: uppercase;
					padding: 30px 150px 0px 250px;
					line-height: 38px;
					background: url("/assets/img/linha-tit-min.png") right bottom no-repeat;
					color: $primaryColor;
					letter-spacing: -1px;
				}
			}
		}

		.page-forms {
			.bg {
				background: #f8f8f8;
				padding: 15px;
				border: 1px solid #dee2e6 !important;
				border-radius: 6px;
			}

			.blockquote-footer {
				font-size: 100%;
				color: #6c757d;
				background: #f8f8f8;
				padding: 15px;
				border: 1px solid #dee2e6!important;
				border-radius: 6px;
			}

			.btn {
				&.bevicred-btn-primary {
					background-color: #222342;
					border-color: #222342;
					padding: 5px 25px 7px;
					border-radius: 5px;
					float: right;

					&:hover {
						background-color: transparent;
						color: #222342!important;
						border: 1px solid #222342;
						padding: 5px 25px 7px;
						border-radius: 5px;
					}

					&#btn-voltar-recapitulacao {
						background-color: #666;
						border-color: #666;
						padding: 5px 25px 7px;
						border-radius: 5px;

						&:hover {
							background-color: transparent;
							color: #666!important;
							border-color: #666;
							padding: 5px 25px 7px;
							border-radius: 5px;
						}
					}
				}
			}

			.btn-remover {
				background: #25cbdb;
				color: #fff;
				border: 1px solid #25cbdb;

				&:hover {
					background: transparent;
					color: #25cbdb;
					border: 1px solid #25cbdb;
				}
			}

			table {
				th {
					padding: 0.55rem!important;
				}

				td,
				th {
					padding: 0.25rem;
					vertical-align: middle;
				}
			}

			h2 {
				color: $extraColor5;
				font-family: $font;
				font-size: 1.8em;
				font-weight: bold;
				line-height: 42px;
				letter-spacing: -1px;
			}

			.cards-manifesto {
				h2 {
					color: #222342;
					font-family: "Nunito",sans-serif;
					font-weight: bold;
					line-height: 42px;
					letter-spacing: normal;
				}

				.items {
					.item {
						width: 100%;
						height: 100%;
						cursor: pointer;
						border-radius: 3px;

						&.elogio {
							background: linear-gradient(360deg, $extraColor2 -73.08%, $extraColor1 122.31%), $elogio;

							.conteudo {
								background-color: $elogio;
							}
						}

						&.sugestao {
							background: linear-gradient(360deg, $extraColor2 -73.08%, $extraColor1 122.31%), $sugestao;

							.conteudo {
								background-color: $sugestao;
							}
						}

						&.reclamacao {
							background: linear-gradient(360deg, $extraColor2 -73.08%, $extraColor1 122.31%), $reclamacao;

							.conteudo {
								background-color: $reclamacao;
							}
						}

						&.denuncia {
							background: linear-gradient(360deg, $extraColor2 -73.08%, $extraColor1 122.31%), $denuncia;

							.conteudo {
								background-color: $denuncia;
							}
						}

						.icone {
							height: 200px;
							display: flex;
							align-items: center;

							svg {
								width: 100%;
								max-height: 65px;
							}

							&.branco {
								svg, path {
									fill: $extraColor2;
								}
							}
						}

						.conteudo {
							padding: 20px 0;

							p {
								text-transform: uppercase;
								color: $extraColor2;
								font-family: $font;
								font-weight: normal;
								font-size: 18px;
								text-align: center;
							}
						}
					}
				}
			}

			p {
				color: $textColor;
				font-family: $font;
				font-size: 18px;

				&.title {
					color: $extraColor4;
				}
			}
		}

	}
}

@media (max-width: 1199.98px) {
	main {
		&.main {
			&.ajuda {
				.listaAjuda {
					.titulo, .desc {
						font-size: .9em;
					}
				}
			}

			.page-header {
				.title-page {
					h1 {
						padding-left: 225px;
					}
				}
			}
		}
	}
}

@media (max-width: 991.98px) {
	main {
		&.main {
			.page-header {
				.title-page {
					h1 {
						padding-left: 165px;
					}
				}
			}

			.page-forms {
				label {
					margin-bottom: 10px;
				}

				h2 {
					font-size: 1.5em;
				}

				.cards-manifesto {
					.items {
						flex-wrap: wrap;

						.item {
							margin: 10px 30px;
						}
					}
				}
			}
		}
	}
}

@media (max-width: 768.98px) {
	main {
		&.main {
			.page-header {
				.title-page {
					h1 {
						background-image: none;
						padding: 30px 0 0 0;
						text-align: left;
					}
				}
			}
		}
	}
}
