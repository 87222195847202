.box-login {
	position: relative;
	background-color: $extraColor2;
	border-radius: 8px!important;

	label {
		margin-bottom: 10px;
	}

	.icon-top {
		position: absolute;
		top: -30px;
		width: 60px;
		height: 60px;
		background-color: $extraColor4;
		color: $extraColor2;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 20px;
		left: calc(50% - 30px);
	}

	.btn {
		&.bevicred-btn-primary {
			background-color: #222342;
			border-color: #222342;
			padding: 5px 25px 7px;
			border-radius: 5px;

			&:hover {
				background-color: transparent;
				color: #222342 !important;
				border: 1px solid #222342;
				padding: 5px 25px 7px;
				border-radius: 5px;
			}
		}
	}
}