footer {
	&.footer {

		h4 {
			font-size: 1.8em;
			font-weight: 200;
			font-family: $font;
			color: $secondaryColor;
			text-transform: uppercase;
		}

		.contato {
			li {
				p {
					margin-bottom: 10px;
				}

				&.contato-telefone {
					p {
						a {
							font-size: 26px;
							font-weight: 900;
							color: $primaryColor;
							font-family: $font;
						}
					}
				}

				&.contato-endereco {
					p {
						color: $textColor;

						&:last-child {
							margin: 0 0 20px;
						}
					}
				}

				&.contato-email {
					p {
						margin: 0 0 20px;

						a {
							font-size: 12px;
						}
					}
				}
			}
		}

		.copyright {
			color: $textColor;
			font-family: $font;
			text-align: center;
			margin-top: 30px;
		}
	}
}