//	Cores Extras
$extraColor1: transparent;
$extraColor2: #fff;
$extraColor3: #000;
$extraColor4: #25cbdb;
$extraColor5: #222343;
$extraColor6: #666666;

$tooltipPrimary: #134a85ff;

//  Cores Padrão
$primaryColor: #134A85;
$secondaryColor: #00AAB5;

// Cores para Hover
$hoverSecondaryColor: #7fd4da;

//  Cores para Bordas
$primaryBorderColor: #e9e9e9;
$secondaryBorderColor: #c3c3c3;

// BoxShadow Primary
$primaryColorShadow: #134A8575;

// Cores para Texto
$textColor: #666666;

// Cores para Midias Sociais
$facebookColor: #3b5a9a;
$instagramColor: #7c4a3a;
$linkedinColor: #0073b2;

// Cores Componentes
$elogio: #00AAB5;
$sugestao: #D41C73;
$reclamacao: #FF8304;
$denuncia: #F41E26;

// Fonts do Projeto
$font: 'Nunito', sans-serif;

// Padrão para elementos Default
* {
	font-family: $font;
}

p {
	margin: 0;
	padding: 0;
	font-family: $font;
}

ul {
	padding: 0;
	margin: 0;
	list-style-type: none;
}

a, button {
	text-decoration: none;
	color: $primaryColor;
	font-family: $font;
	outline: none;

	&:hover {
		text-decoration: none;
		color: $primaryColor;
	}
}

h1 {
	padding: 0;
	margin: 0;
	font-family: $font;
}

label {
	margin: 0;
}