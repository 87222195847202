.bevicred-btn-primary {
	background-color: $primaryColor;
	color: $extraColor2;
	cursor: pointer;

	&:hover, &:focus, &:active {
		background-color: $primaryColor;
		color: $extraColor2;
		box-shadow: none;
		outline: none;
	}
}

.bevicred-outline-primary {
	color: $primaryColor;
	border-color: $primaryColor;

	&:hover, &:focus, &:active {
		color: $extraColor2;
		background-color: $primaryColor;
		border-color: $primaryColor;
	}

	&:focus {
		box-shadow: 0 0 0 0.2rem $primaryColorShadow;
	}
}