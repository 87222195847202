.form-dados-manifesto {
	.conteudo-mensagem {
		.bloco {

			.titulo {
				p {
					color: $extraColor5;
					font-size: 20px;
					font-weight: bold;
				}
			}

			&.descricao {
				.dados {

					p {
						display: none;
						font-size: 18px;
						margin-bottom: 20px;
					}

					&.elogio {
						p {
							&.text-elogio {
								display: block;
							}
						}
					}

					&.sugestao {
						p {
							&.text-sugestao {
								display: block;
							}
						}
					}

					&.reclamacao {
						p {
							&.text-reclamacao {
								display: block;
							}
						}
					}

					&.denuncia {

						p {
							&.text-denuncia {
								display: block;
							}
						}

					}

					label {
						font-weight: normal;
						font-size: 16px;
						margin-bottom: 10px;
					}
				}
			}

			&.fileUploadArea {
				.titulo {
					p {
						text-align: left;
						font-size: 16px;
						font-weight: 100;
					}
				}

				.box {
					label {
						font-size: .9em;
					}
				}
			}

			&.envolvidos {
				.btn-addEnvolvido {
					border: none;
					background-color: transparent;
					position: absolute;
					right: 0;
					top: 0;

					&:hover, &:focus, &:active {
						border: none;
						outline: none;
					}
				}
			}



		}
	}
}

@media (max-width: 991.98px) {
	.form-dados-manifesto {
		.conteudo-mensagem {
			.bloco {
				.titulo {
					p {
						font-size: 1.5em;
					}
				}
			}
		}
	}
}

@media (max-width: 768.98px) {
	.form-dados-manifesto {
		.conteudo-mensagem {
			.bloco {
				.titulo {
					p {
						font-size: 1.2em;
					}
				}
			}
		}
	}
}